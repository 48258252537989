/** NEW Left sidebar CSS  */
.sidebarLeft {
	position: fixed;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-100);
	max-width: min(530px, 100vw);
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin-right: auto;
	max-height: 100vh;
	padding: 0px 6px;
	height: 100vh;
	z-index: 94;
	left: 0;
}
.sidebarLeft.expandedBorder {
	-webkit-box-shadow: var(--shadow-sm);
	box-shadow: var(--shadow-sm);
}
.sidebarLeft .navCol {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 105px 10px 24px 10px;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-height: 100vh;
	max-width: 72px;
	height: 100%;
	width: 100%;
	gap: 24px;
}
.sidebarLeft .navCol.extended {
	max-width: 260px;
	width: 260px;
}
.sidebarLeft .navCol.subNav {
	border-left: 1px solid var(--gray-300);
	padding-top: 25px;
	max-height: 90%;
	top: 80px;
}
.sidebarLeft .navCol .currUserProfile {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 10px 0px 10px 0px;
	max-width: 100%;
	width: 260px;
}
.sidebarLeft .navCol .currUserProfile h4 {
	font-size: 0.875em;
}
.sidebarLeft .navCol .currUserProfile p {
	color: var(--gray-700);
	word-break: break-all;
	font-size: 0.875em;
	max-width: 100%;
	margin: 0px;
	width: 100%;
}
.sidebarLeft .navCol .innerCol {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	gap: 8px;
}
.sidebarLeft .navCol .separator {
	border-bottom: 1px solid var(--gray-300);
	width: 100%;
}
.sidebarLeft .navCol .innerCol .colSegment {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-top: 1px solid var(--gray-300);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 24px;
	width: 100%;
	gap: 24px;
}
.sidebarLeft .navCol .innerCol .colSegment .navItem.noPadding {
	border-radius: 50%;
	gap: 10px;
}
.sidebarLeft .navCol .innerCol .colSegment .navItem.noPadding {
	padding: 0px;
}
.sidebarLeft .navCol .innerCol .colSegment .navItem.noPadding .chevronIcon {
	padding-right: 8px;
}
.sidebarLeft .navCol.extended .innerCol .colSegment .navItem {
	border-radius: 25px 8px 8px 25px;
}
.sidebarLeft .navCol .innerCol .navItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 6px;
	cursor: pointer;
	min-width: 40px;
	padding: 8px;
	height: 40px;
	width: 100%;
	gap: 16px;
}
.sidebarLeft .navCol .innerCol .navItem:active {
	-webkit-box-shadow: 0px 0px 0px 4px #c2ecf0, 1px 1px 14px 0px rgba(0, 0, 0, 0.6);
	box-shadow: 0px 0px 0px 4px #c2ecf0, 1px 1px 14px 0px rgba(0, 0, 0, 0.6);
	outline: none;
}
.sidebarLeft .navCol .innerCol .navItem .profileImg,
.sidebarLeft .navCol .innerCol .navItem .teamChoicesIcon {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 50%;
	cursor: pointer;
	min-width: 40px;
	max-width: 40px;
	height: 40px;
}
.sidebarLeft .navCol .innerCol .navItem,
.sidebarLeft .navCol .innerCol .navItem a,
.sidebarLeft .navCol .innerCol .navItem h4 {
	color: var(--gray-700);
	font-weight: 700;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	white-space: nowrap;
	user-select: none;
}
.sidebarLeft .navCol .innerCol .navItem:hover {
	background-color: var(--gray-25);
	-webkit-box-shadow: var(--shadow-sm);
	box-shadow: var(--shadow-sm);
	color: var(--gray-600);
}
.sidebarLeft .navCol .innerCol .navItem:hover .navIcon {
	fill: var(--gray-500);
}
.sidebarLeft .navCol .innerCol .navItem.highlight {
	background-color: var(--white);
}
.sidebarLeft .navCol .innerCol .navItem.highlight:hover {
	background-color: var(--gray-25);
	-webkit-box-shadow: var(--shadow-sm);
	box-shadow: var(--shadow-sm);
	color: var(--gray-900);
}
.sidebarLeft .navCol .innerCol .navItem.highlight:hover .navIcon {
	fill: var(--gray-700);
}
.sidebarLeft .navCol .innerCol .navItem.highlight.buy .navIcon {
	fill: var(--buy-700-main);
}
.sidebarLeft .navCol .innerCol .navItem.highlight.buy h4 {
	color: var(--buy-700-main);
}
.sidebarLeft .navCol .innerCol .navItem.highlight.sell .navIcon {
	fill: var(--sell-700-main);
}
.sidebarLeft .navCol .innerCol .navItem.highlight.sell h4 {
	color: var(--sell-700-main);
}
.sidebarLeft .navCol .innerCol .navItem .sidebarIcon {
	fill: var(--gray-700);
	border-radius: 6px;
	min-height: 24px;
	min-width: 24px;
	height: 24px;
	width: 24px;
}
.sidebarLeft .navCol .innerCol .navItem .largeIcon {
	fill: var(--gray-700);
	height: 40px;
	width: 40px;
}
.sidebarLeft .navCol .innerCol .navItem .chevronIcon {
	fill: var(--gray-900);
	border-radius: 6px;
	min-height: 14px;
	margin-left: auto;
	min-width: 14px;
	height: 14px;
	width: 14px;
}

.sidebarLeft .profile {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	max-width: 100%;
	width: 250px;
}
.sidebarLeft .profile .usersCol {
	display: flex;
	flex-direction: column;
	max-height: 40vh;
	overflow: auto;
}
.sidebarLeft .profile .profileContainer {
	overflow: visible;
	max-width: 100%;
}
.sidebarLeft .profile .profileContainer .profileDetails {
	max-width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.sidebarLeft .profile .profileContainer .profileDetails p {
	color: var(--gray-700);
	word-break: break-all;
	font-size: 0.875em;
	max-width: 100%;
	width: 100%;
}
.sidebarLeft .profile .profileContainer .profileDetails h4 {
	font-size: 0.875em;
}

.sidebarLeft .profile img {
	border-radius: var(--borderRadiusBtn);
	margin-right: 10px;
	min-width: 40px;
	height: 40px;
	width: 40px;
}

.sidebarLeft .profile .innerCol.borderBottom {
	border-bottom: 1px solid var(--gray-300);
	padding-bottom: 10px;
	margin-bottom: 10px;
	width: 100%;
}

.sidebarLeft .navCol .dropdown {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDark);
	box-shadow: 0px 0px 4px var(--boxShadowDark);
	border-radius: 0px 12px 12px 12px;
	padding: 10px;
	background: #ffffff;
	background: var(--white);
	top: 100%;
	max-height: 40vh;
	max-width: 90vw;
	min-width: 120px;
	z-index: 1;
	left: 100%;
	gap: 12px;
}
.sidebarLeft .navCol .dropdownFlippedVert {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDark);
	box-shadow: 0px 0px 4px var(--boxShadowDark);
	border-radius: 12px 12px 12px 0px;
	padding: 10px;
	background: #ffffff;
	background: var(--white);
	bottom: 100%;
	max-height: 40vh;
	max-width: 90vw;
	min-width: 120px;
	z-index: 1;
	left: 100%;
	gap: 12px;
}
.sidebarLeft .navCol .dropdownFlippedHor {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDark);
	box-shadow: 0px 0px 4px var(--boxShadowDark);
	border-radius: 12px 0px 12px 12px;
	padding: 10px;
	background: #ffffff;
	background: var(--white);
	top: 100%;
	max-height: 40vh;
	max-width: 90vw;
	min-width: 120px;
	z-index: 1;
	right: 0px;
	gap: 12px;
}
.sidebarLeft .navCol .dropdownFlippedVertHor {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDark);
	box-shadow: 0px 0px 4px var(--boxShadowDark);
	border-radius: 12px 12px 0px 12px;
	padding: 10px;
	background: #ffffff;
	background: var(--white);
	bottom: 100%;
	max-height: 40vh;
	max-width: 90vw;
	min-width: 120px;
	z-index: 1;
	right: 0px;
}
.sidebarLeft .navCol .dropdown .navSection {
	width: 100%;
}

/* Sidebar-related CSS RIGHT SIDE */
.sidebarContentWrapper {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow-x: hidden;
	margin-right: 20px;
	overflow-y: auto;
	max-height: 100%;
	max-width: 100%;
	width: 100%;
}

.rightSidebarList {
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDark);
	box-shadow: 0px 0px 4px var(--boxShadowDark);
	-webkit-animation: sidebarSlideinRight 0.5s ease;
	animation: sidebarSlideinRight 0.5s ease;
	background-color: var(--lightGray);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: fixed;
	right: -400px;
	z-index: 100;
	height: 100%;
	width: 400px;
	top: 0px;
}

.sidebarContentWrapper::-webkit-scrollbar-thumb {
	background: var(--turqMain);
}

.sidebarContentWrapper::-webkit-scrollbar-thumb:hover {
	background: var(--turqDark);
}

.rightSidebarTextContainer {
	margin: 10px 40px;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--gray);
}

.rightSidebarChatContainer {
	margin: 10px 30px;
	padding: 10px;
	border-radius: 8px;
	border-bottom: 1px solid var(--gray);
	background-color: var(--white);
}

.rightSidebarTextContainer .sidebarTextBody {
	margin-left: 20px;
	color: var(--gray);
}

.rightSidebarList .sidebarTextHeader {
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	line-height: 30px;
}

.sidebarTextRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.slideOutRight {
	-webkit-animation: sidebarSlideOutRight 0.5s ease;
	animation: sidebarSlideOutRight 0.5s ease;
	right: -400px;
}

.slideInRight {
	-webkit-animation: sidebarSlideInRight 0.5s ease;
	animation: sidebarSlideInRight 0.5s ease;
	right: 0px;
}

.rightSidebar {
	height: 100%;
	z-index: 101;
	position: fixed;
}

.rightSidebarTopRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 32px 0px 40px 10px;
}

.cropCont {
	height: 300px;
	background-color: var(--gray-300);
}

@media only screen and (max-width: 1312px) {
	.sidebarLeft.extended {
		position: fixed;
		left: 0px;
	}
}
@media only screen and (max-width: 680px) {
	.sidebarLeft {
		top: 60px;
	}
	.sidebarLeft .navCol {
		padding-bottom: 100px;
		padding-top: 25px;
		height: 93.5vh;
	}
	.sidebarLeft .navCol .innerCol .navItem {
		padding: 6px;
	}
	.sidebarLeft .navCol .innerCol .navItem h4 {
		font-size: 1em;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: wrap;
	}
	.sidebarLeft .navCol .innerCol .navItem .sidebarIcon {
		min-height: 20px;
		min-width: 20px;
		height: 20px;
		width: 20px;
	}
	.sidebarLeft .navCol.subNav {
		top: 0px;
	}
	.profileContainerChoice,
	.profileContainer {
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 12px;
	}
}
@media only screen and (max-width: 640px) {
	.leftSidebarList,
	.rightSidebarList {
		width: 100%;
	}
	.leftSidebarTopRow {
		margin: 55px 20px 20px 20px;
		max-width: 100%;
	}
	.leftSidebarTopRow .logo {
		margin: auto;
	}

	.slideOutLeft {
		left: -100%;
		-webkit-animation: sidebarSlideOutLeftMobile 0.5s ease;
		animation: sidebarSlideOutLeftMobile 0.5s ease;
	}
	.slideInLeft {
		left: 0px;
		-webkit-animation: sidebarSlideInLeftMobile 0.5s ease;
		animation: sidebarSlideInLeftMobile 0.5s ease;
	}
	.slideOutRight {
		left: -100%;
		-webkit-animation: sidebarSlideOutRightMobile 0.5s ease;
		animation: sidebarSlideOutRightMobile 0.5s ease;
	}
	.slideInRight {
		left: 0px;
		-webkit-animation: sidebarSlideInRightMobile 0.5s ease;
		animation: sidebarSlideInRightMobile 0.5s ease;
	}
}
@media only screen and (max-width: 468px) {
	.sidebarLeft .navCol.extended {
		width: 50vw;
	}
	.sidebarLeft .navCol .currUserProfile p {
		display: none;
	}
}
