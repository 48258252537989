/* General order page CSS */
.orderDetailsCont {
	background-color: var(--purchaseBgLight);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 16px;
	padding: 30px 70px;
	max-width: 1540px;
	margin: auto;
	width: 100%;
}

.orderDetailsCont .floatingHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	margin: 0px 0px 20px 0px;
	width: 100%;
}
.orderDetailsCont .floatingHeader .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0px 0px 0px auto;
	height: 32px;
}
.orderDetailsCont .floatingHeader .btnCont .btn {
	height: 32px;
}
.orderGridCont {
	display: -moz-grid;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 25px 1fr 25px 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-rows: 1fr 25px 1fr 25px 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	border-radius: 24px;
	max-width: 1540px;
	grid-gap: 25px;
}
.orderGridCont > *:nth-child(1) {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
}
.orderGridCont > *:nth-child(2) {
	-ms-grid-row: 1;
	-ms-grid-column: 3;
}
.orderGridCont > *:nth-child(3) {
	-ms-grid-row: 1;
	-ms-grid-column: 5;
}
.orderGridCont > *:nth-child(4) {
	-ms-grid-row: 3;
	-ms-grid-column: 1;
}
.orderGridCont > *:nth-child(5) {
	-ms-grid-row: 3;
	-ms-grid-column: 3;
}
.orderGridCont > *:nth-child(6) {
	-ms-grid-row: 3;
	-ms-grid-column: 5;
}
.orderGridCont > *:nth-child(7) {
	-ms-grid-row: 5;
	-ms-grid-column: 1;
}
.orderGridCont > *:nth-child(8) {
	-ms-grid-row: 5;
	-ms-grid-column: 3;
}
.orderGridCont > *:nth-child(9) {
	-ms-grid-row: 5;
	-ms-grid-column: 5;
}
.orderGridCont .gridItemSkeleton {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
	-webkit-box-pack: stretch;
	-ms-flex-pack: stretch;
	justify-content: stretch;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 20px;
	max-width: 925px;
	padding: 12px;
	height: 330px;
	opacity: 0.3;
}
.orderGridCont .gridItemOrder {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
	-webkit-box-pack: stretch;
	-ms-flex-pack: stretch;
	justify-content: stretch;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 20px;
	max-width: 925px;
	padding: 12px;
	height: 330px;
}
.orderGridCont .spanTwo {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 2;
	grid-column-end: span 2;
}
.orderGridCont .gridItemOrder.references {
	-ms-grid-column: 1;
	grid-column: 1;
}
.orderGridCont .gridItemOrder.progress {
	-ms-grid-column: 2;
	grid-column: 2;
}
.orderGridCont .gridItemOrder.financial {
	-ms-grid-column: 3;
	grid-column: 3;
}
.orderGridCont .gridItemOrder.items {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 2;
	grid-column-end: span 2;
}
.orderGridCont .gridItemOrder.files {
	-ms-grid-column: 3;
	grid-column: 3;
}
.orderGridCont .gridItemOrder.tracking {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 2;
	grid-column-end: span 2;
}
.orderGridCont .gridItemOrder.suborders {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 2;
	grid-column-end: span 2;
}
.orderGridCont .gridItemOrder.external {
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: 2;
	grid-column-end: span 2;
	-ms-grid-row: 4;
	grid-row: 4;
}
.orderGridCont .gridItemOrder.labmanager {
	-ms-grid-column: 3;
	grid-column-start: 3;
	-ms-grid-column-span: 0;
	grid-column-end: 3;
	-ms-grid-row: 4;
	grid-row: 4;
}
.orderGridCont .gridItemOrder.dentally .colCont {
	overflow: auto;
	gap: 12px;
}
.orderGridCont .gridItemOrder.dentally .colCont h4 {
	font-size: 0.875em;
}
.orderGridCont .gridItemOrder .gridHeader {
	margin: 12px 0px 0px 12px;
}
.orderGridCont .gridItemOrder.references .row .text,
.orderGridCont .gridItemOrder.progress .row .text,
.orderGridCont .gridItemOrder.financial .row .text {
	margin: 2px 0px 2px 12px;
}
.orderGridCont .gridItemOrder .gridIconCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-item-align: start;
	align-self: flex-start;
	gap: 10px;
}
.orderGridCont .gridItemOrder .gridIcon,
.orderGridCont .gridItemOrder .gridIconRegular {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	-ms-flex-item-align: start;
	align-self: flex-start;
	border-radius: 50%;
	cursor: pointer;
	height: 32px;
	width: 32px;
}
.orderGridCont .gridItemOrder .gridIconRegular {
	padding: 6px;
	height: 20px;
	width: 20px;
}
.orderGridCont .gridItemOrder .gridIcon:hover,
.orderGridCont .gridItemOrder .gridIconRegular:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.orderGridCont .gridItemOrder .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	gap: 6px;
}
.orderGridCont .gridItemOrder .btnCont {
	margin: auto 0px 0px auto;
}
.orderGridCont .gridItemOrder .row .text {
	font-size: 0.875em;
	margin: 2px 0px;
	text-align: left;
	width: 50%;
}
.orderGridCont .gridItemOrder .row .data {
	word-wrap: break-word;
	font-size: 0.875em;
	overflow-x: hidden;
	overflow-y: overlay;
	text-align: left;
	margin: 2px 0px;
	width: 50%;
}
.orderGridCont .gridItemOrder .row .infoText {
	margin: 12px 0px 0px 60px;
	font-size: 0.75em;
	color: var(--gray);
}
.orderGridCont .gridItemOrder .row .infoText a {
	color: var(--gray);
}
.orderGridCont .gridItemOrder .row.highlight {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 20px;
}
.orderGridCont .gridItemOrder .row.highlight .text {
	font-size: 1em;
}
.orderGridCont .gridItemOrder .row.highlight .data {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.orderGridCont .gridItemOrder .colCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0px 12px;
	max-width: 100%;
	height: 80%;
}
.orderGridCont .gridItemOrder .col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 100%;
	max-width: 100%;
}
.orderGridCont .gridItemOrder.items .rowItem:nth-child(1) {
	width: 40%;
}
.orderGridCont .gridItemOrder.items .rowItem:nth-child(2) {
	width: 40%;
}
.orderGridCont .gridItemOrder.items .rowItem:nth-child(3) {
	width: 80px;
}
.orderGridCont .gridItemOrder.comments .chatBox {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding-right: 6px;
	padding-top: 12px;
	padding: 12px 12px 0px 12px;
	max-height: 300px;
	min-height: 0px;
	border: none;
}
.orderGridCont .gridItemOrder.comments .chatTimestamp {
	margin-left: auto;
}
.orderGridCont .gridItemOrder.comments .chatMessage,
.orderGridCont .gridItemOrder.comments .chatMessageUser {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 8px;
	margin: 8px 0px;
	max-width: 100%;
	word-break: break-all;
}
.orderGridCont .gridItemOrder.comments .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 1px solid var(--gray);
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	gap: 5px;
}
.orderGridCont .gridItemOrder.comments .row:nth-child(1) {
	border-bottom: none;
}
.orderGridCont .gridItemOrder.comments .row .rowItem:nth-child(1),
.orderGridCont .gridItemOrder.comments .row .rowItem:nth-child(3) {
	max-width: 20%;
}
.orderGridCont .gridItemOrder .colCont .rowItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 32px;
	width: 200px;
}
.orderGridCont .gridItemOrder .colCont .itemCont {
	padding-bottom: 6px;
	margin-bottom: 12px;
}
.orderGridCont .gridItemOrder.tracking .colCont .itemCont .row .rowItem {
	word-wrap: break-word;
	max-width: 16.5%;
	width: 100%;
}
.orderGridCont .gridItemOrder .colCont .rowItem .subHeader {
	font-variation-settings: var(--bold);
	font-size: 0.75em;
	margin-bottom: 10px;
}
.orderGridCont .gridItemOrder .row.data {
	padding: 10px 0px;
}
.orderGridCont .gridItemOrder .row.data .rowItem .subHeader {
	display: none;
}
.orderGridCont .segmentText .bodyText {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	font-size: 0.875em;
	margin: 0px;
}
.orderGridCont .imgContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	max-width: 100%;
	gap: 15px;
}
.orderGridCont .imgItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	width: 92.5px;
	gap: 12px;
}
.orderGridCont .imgItem .imgThumbnail {
	height: 50px;
	width: 75px;
	margin: 0px 2px;
	cursor: pointer;
}
.orderGridCont .imgItem .fileName {
	font-size: 0.75em;
	max-width: 100%;
	word-wrap: break-word;
}
.orderDetailsCont .fileIcon {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.orderDetailsCont .newFile {
	border-bottom: 1px solid var(--gray);
	margin-bottom: 20px;
	padding-bottom: 20px;
	width: 300px;
}
.orderDetailsCont .arrowExpand {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 18px;
	right: 16px;
	fill: var(--gray);
	cursor: pointer;
}
.orderDetailsCont .expandedRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 6px;
	padding: 0px 16px;
	height: 60px;
	width: 100%;
}
.orderDetailsCont .plus {
	cursor: pointer;
}

/** Buyer related CSS  */
.orderDetailsCont.purchase h2 {
	color: var(--purchase);
}
.orderDetailsCont.purchase .orderPageDiscIcon,
.orderDetailsCont.purchase .orderPageIcon,
.orderDetailsCont.purchase .plus {
	fill: var(--purchase);
}
.orderDetailsCont.purchase .orderPageIcon.disabled {
	fill: var(--successGreen);
	-webkit-box-shadow: none;
	box-shadow: none;
	cursor: default;
}
.orderDetailsCont.purchase .btn.primary,
.orderDetailsCont.purchase .btn3-primary {
	color: var(--white);
	background-color: var(--purchase);
}
.orderDetailsCont.purchase .btn.secondary {
	color: var(--purchase);
	background-color: var(--white);
}
.orderDetailsCont.purchase .btn3-inverted {
	color: var(--purchase);
	background-color: var(--purchaseBgLight);
}
.orderDetailsCont.purchase .orderGridCont .gridHeader {
	color: var(--purchase);
}
.orderDetailsCont.purchase .orderGridCont .gridItemOrder .row.highlight .data {
	color: var(--purchase);
}
.orderDetailsCont.purchase .orderGridCont .gridItemOrder .gridIcon,
.orderDetailsCont.purchase .orderGridCont .gridItemOrder .gridIconRegular {
	fill: var(--purchase);
}
.orderDetailsCont.purchase .orderGridCont .gridItemOrder .subHeader {
	color: var(--purchaseBgListing);
}
.orderDetailsCont.purchase .expandedRow {
	background-color: var(--purchaseBgLight);
}

/** Seller related CSS  */
.orderDetailsCont.sale h2 {
	color: var(--sale);
}
.orderDetailsCont.sale .orderPageDiscIcon,
.orderDetailsCont.sale .orderPageIcon,
.orderDetailsCont.sale .plus {
	fill: var(--sale);
}
.orderDetailsCont.sale .orderPageIcon.disabled {
	fill: var(--successGreen);
	-webkit-box-shadow: none;
	box-shadow: none;
	cursor: default;
}
.orderDetailsCont.sale .btn.primary,
.orderDetailsCont.sale .btn3-primary {
	color: var(--white);
	background-color: var(--saleBg);
}
.orderDetailsCont.sale .btn.secondary {
	color: var(--saleBg);
	background-color: var(--white);
}
.orderDetailsCont.sale .btn3-inverted {
	color: var(--sale);
	background-color: var(--saleBgLight);
}
.orderDetailsCont.sale {
	background-color: var(--saleBgBright);
}
.orderDetailsCont.sale .orderGridCont .gridHeader {
	color: var(--sale);
}
.orderDetailsCont.sale .orderGridCont .gridItemOrder .row.highlight .data {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: var(--sale);
}
.orderDetailsCont.sale .orderGridCont .gridItemOrder .gridIcon,
.orderDetailsCont.sale .orderGridCont .gridItemOrder .gridIconRegular {
	fill: var(--sale);
}
.orderDetailsCont.sale .orderGridCont .gridItemOrder .subHeader {
	color: var(--saleBgListing);
}
.orderDetailsCont.sale .expandedRow {
	background-color: var(--saleBgLight);
}

.orderInput {
	position: relative;
	border: 1px solid var(--gray);
	border-radius: 3px;
	font-size: 1em;
	padding: 0px 5px;
	width: 100px;
	height: 28px;
	margin-right: 10px;
}

.orderInput:focus {
	border: 2px solid var(--turqMain);
	padding: 0px 4px;
}

/* Notification */
.orderNotificationBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 960px;
	-webkit-box-shadow: 1px 1px 6px 1px var(--gray);
	box-shadow: 1px 1px 6px 1px var(--gray);
	border-radius: var(--borderRadiusReg);
	padding: 5px 20px;
	margin: 10px 0px;
	background-color: var(--white);
}

.orderNotificationBar p {
	font-weight: 600;
}

.orderNotificationBar button {
	margin-left: 10px;
}

/* Order List Pagination */
.paginationContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-collapse: collapse;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	max-width: 100%;
	padding: 20px;
}

.paginationContainer ul {
	padding: 0px;
	list-style-type: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: var(--gray);
}

.paginationPage {
	cursor: pointer;
	height: 32px;
	min-width: 22px;
}

.paginationPage:nth-child(2) {
	border-left: 1px solid var(--gray);
}

.paginationLink {
	text-align: center;
	padding: 0 12px;
	height: 32px;
	width: 100%;
}

.paginationActive {
	background-color: var(--mediumGray);
}

.paginationPrevious {
	cursor: pointer;
	height: 32px;
	min-width: 40px;
	text-align: center;
	border-top: 1px solid var(--gray);
	border-left: 1px solid var(--gray);
	border-bottom: 1px solid var(--gray);
	border-radius: 25px 0 0 25px;
}

.paginationNext {
	cursor: pointer;
	height: 32px;
	min-width: 40px;
	text-align: center;
	border-radius: 0 25px 25px 0;
}

.paginationNext,
.paginationPage,
.paginationBreak {
	border-top: 1px solid var(--gray);
	border-right: 1px solid var(--gray);
	border-bottom: 1px solid var(--gray);
}

.paginationPrevious a,
.paginationNext a,
.paginationBreak a {
	width: 100%;
}

.paginationBreak {
	cursor: pointer;
	min-width: 30px;
	text-align: center;
}

.paginationNext,
.paginationNext a,
.paginationPrevious,
.paginationPrevious a,
.paginationPage,
.paginationPage a,
.paginationBreak,
.paginationBreak a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 32px;
}

.listMessage {
	padding: 20px;
	margin-bottom: 40px;
}

.historyLog {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding-right: 24px;
	max-height: 500px;
	margin-top: 36px;
	overflow: auto;
	gap: 12px;
}
.historyLog h4 {
	padding-bottom: 12px;
	margin: 0px;
}
.historyLog .logItem {
	border-bottom: 1px solid var(--gray-300);
}
.historyLog .logItem:last-child {
	border-bottom: none;
}
.historyLog .logItem p {
	color: var(--smoke-gray-400);
}

@media only screen and (max-width: 1280px) {
	.orderDetailsCont {
		padding: 16px;
	}
	.orderGridCont {
		-ms-grid-columns: 1fr 16px 1fr;
		grid-template-columns: 1fr 1fr;
		grid-gap: 16px;
	}
	.orderGridCont .gridItemOrder {
		max-width: 100%;
	}
	.orderGridCont .gridItemOrder.references {
		-ms-grid-column: 1;
		grid-column: 1;
	}
	.orderGridCont .gridItemOrder.progress {
		-ms-grid-column: 2;
		grid-column: 2;
	}
	.orderGridCont .gridItemOrder.financial {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 2;
		grid-row: 2;
	}
	.orderGridCont .gridItemOrder.files {
		-ms-grid-column: 2;
		grid-column: 2;
		-ms-grid-row: 2;
		grid-row: 2;
	}
	.orderGridCont .gridItemOrder.comments {
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row: 4;
		grid-row: 4;
	}
	.orderGridCont .gridItemOrder.external {
		-ms-grid-column-span: 2;
		grid-column: span 2;
		-ms-grid-row: 6;
		grid-row: 6;
	}
	.orderGridCont .gridItemOrder.labmanager {
		-ms-grid-column-span: 2;
		grid-column: span 2;
		-ms-grid-row: 7;
		grid-row: 7;
	}
}

@media only screen and (max-width: 864px) {
	.orderGridCont {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}
	.orderGridCont .gridItemOrder.references {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 1;
		grid-row: 1;
	}
	.orderGridCont .gridItemOrder.progress {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 2;
		grid-row: 2;
	}
	.orderGridCont .gridItemOrder.items {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 3;
		grid-row: 3;
	}
	.orderGridCont .gridItemOrder.files {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 4;
		grid-row: 4;
	}
	.orderGridCont .gridItemOrder.financial {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 5;
		grid-row: 5;
	}
	.orderGridCont .gridItemOrder.tracking {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 6;
		grid-row: 6;
	}
	.orderGridCont .gridItemOrder.suborders {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 7;
		grid-row: 7;
	}
	.orderGridCont .gridItemOrder.comments {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 8;
		grid-row: 8;
	}
	.orderGridCont .gridItemOrder.external {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 9;
		grid-row: 9;
	}
	.orderGridCont .gridItemOrder.labmanager {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 10;
		grid-row: 10;
	}
	.orderDetailsCont .floatingHeader {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		gap: 12px;
	}
	.orderDetailsCont .floatingHeader .btnCont {
		margin: 0px;
	}
	.orderGridCont .gridItemOrder .row.header {
		display: none;
	}
	.orderGridCont .gridItemOrder .row.data {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}
	.orderGridCont .gridItemOrder .row.data .rowItem {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}
	.orderGridCont .gridItemOrder .row.data .rowItem .subHeader {
		display: block;
		margin: 20px 0px 0px 0px;
	}
	.orderListingCont .cardCont .row .orderItemText:nth-child(2),
	.orderListingCont .flexColumn-2 {
		display: none;
	}
	.orderListingCont .cardCont .row .orderItemText {
		margin-left: 10px;
		width: 25%;
	}
	.orderListingCont .cardCont .row .orderItemText:first-child {
		min-width: 100px;
		margin: 0px;
		width: 45%;
	}
	.orderListingCont .cardCont .row .orderItemText:last-child {
		margin-left: 10px;
		width: 50%;
	}
	.orderItemCenter .colRow {
		min-width: 200px;
	}

	.orderItemCenter .colRow {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.orderItemCenter .flexColumn-1 {
		padding: 0px;
	}
	.orderItemCenter .flexColumn-1 .prescription {
		padding: 0px 0px 20px 0px;
	}
	.orderGridCont .gridItemOrder .colCont .rowItem {
		width: 100%;
	}
}

@media only screen and (max-width: 465px) {
	.orderListingCont .cardCont .row .orderItemText:nth-child(3) {
		display: none;
	}
	.orderItemCenter {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.flexColumn-1,
	.flexColumn-2,
	.flexColumn-3 {
		width: 100%;
		margin: 0px;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.cardCont .img {
		width: 100%;
		max-width: 120px;
	}
	.cardCont .row {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.orderItemCenter .colRow {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
	}
	.orderGridCont .gridItemOrder.tracking .rowItem:nth-child(3),
	.orderGridCont .gridItemOrder.tracking .rowItem:nth-child(4),
	.orderGridCont .gridItemOrder.tracking .rowItem:nth-child(5) {
		display: none;
	}

	.orderItemCont .floatingHeader {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0px;
		margin: 0px;
	}

	.filterCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 12px;
		gap: 20px;
	}
	.orderGridCont .gridItemOrder .row .infoText {
		display: none;
	}
	.orderGridCont .gridItemOrder.tracking .colCont .itemCont .row .rowItem {
		word-wrap: break-word;
		max-width: 33%;
		width: 100%;
	}
}
