.icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.icon.warning {
	fill: var(--warning-500);
}

.discIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 6px;
}

.iconWhite {
	color: var(--white);
	font-size: 1.7em;
}

.barsIcon {
	margin: 19px 40px;
	cursor: pointer;
}

.uploadIcon {
	margin: 5px 20px 8px 0px;
	height: 28px;
	width: 28px;
}

.crossIcon {
	margin: 22px 0px 22px 40px;
	height: 22px;
	width: 22px;
	font-size: 1.5em;
	cursor: pointer;
}

.iconCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 36px;
	width: 36px;
	border-radius: 25px;
	padding: 6px;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	cursor: pointer;
}

.iconCont:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}

.listingIcon.turq path {
	height: 24px;
	width: 24px;
	fill: var(--turqMain) !important;
}

.listingIcon.gray {
	height: 24px;
	width: 24px;
	fill: var(--gray-500);
}

.shareIcon,
.pinIcon {
	margin: 0px 10px 0px 0px;
	font-size: 1em;
	border-radius: 50%;
	background-color: var(--lightGray);
	color: var(--gray);
}

.cartBuyIcon,
.cartSellIcon,
.dashboardIcon,
.chartIcon,
.appsIcon,
.supportIcon,
.legalIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	fill: var(--black);
	width: 24px;
	height: 24px;
}

.bellIcon {
	border-radius: 50%;
	fill: var(--gray);
	height: 40px;
	width: 40px;
}

.rankingsIcon {
	fill: var(--white);
	height: 40px;
	width: 40px;
}

.transitIcon {
	-webkit-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
	width: 28px;
	height: 28px;
}

.supplierDraftIcon {
	fill: var(--gray);
	height: 30px;
	width: 30px;
}

.supplierTrustedIcon {
	width: clamp(24px, 24px, 24px);
	height: clamp(24px, 24px, 24px);
}

.manageTeamIcon,
.registerTeamIcon,
.getSupportIcon,
.draftIcon,
.dispatchedIcon,
.ongoingIcon,
.incomingIcon,
.pendingIcon {
	width: 28px;
	height: 28px;
}

.saveIcon,
.plusIcon,
.signOutAltSolid {
	width: 24px;
	height: 24px;
}

.paperPlaneIcon {
	fill: var(--white);
	height: 25px;
	width: 25px;
	padding-right: 3px;
}

.caretRightIcon {
	height: 20px;
	width: 20px;
	margin: auto 0px auto auto;
}

.caretDownIcon {
	height: 20px;
	width: 20px;
	margin: auto 0px auto auto;
}

.caretIconAbsoluteGray {
	fill: var(--gray);
	position: absolute;
	background-color: var(--white);
	height: 20px;
	width: 20px;
	right: 10px;
}

.caretIconAbsoluteTurq {
	fill: var(--turqMain);
	position: absolute;
	background-color: var(--white);
	height: 20px;
	width: 20px;
	right: 10px;
}

.caretIconAbsoluteSuccess {
	fill: var(--successGreen);
	position: absolute;
	background-color: var(--white);
	height: 20px;
	width: 20px;
	right: 10px;
}

.caretIconAbsoluteWarning {
	fill: var(--warningRed);
	position: absolute;
	background-color: var(--white);
	height: 20px;
	width: 20px;
	right: 10px;
}

.caretUpIcon {
	height: 20px;
	width: 20px;
	margin: auto 0px auto auto;
}

.chevronIconGray {
	fill: var(--gray);
	height: 20px;
	width: 20px;
}

.caretRightIconGray {
	fill: var(--gray);
	height: 15px;
	width: 15px;
	margin: auto auto auto 5px;
}

.caretRightIconWhite {
	fill: var(--white);
	height: 15px;
	width: 15px;
	margin: auto auto auto 5px;
}

.caretDownIconGray {
	fill: var(--gray);
	height: 15px;
	width: 15px;
}

.caretUpIconGray {
	fill: var(--gray);
	height: 15px;
	width: 15px;
	margin: auto 0px auto auto;
}

.teamIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-size: 28px 28px;
	font-size: 1em;
	font-variation-settings: var(--bold);
	padding: 5px;
	height: 30px;
	width: 30px;
	text-align: center;
	color: var(--white);
	background-color: var(--darkBlue);
	border-radius: 50%;
}
.navItem .teamIcon.purple {
	background-color: var(--purple-600);
}
.navItem .teamIcon.pink {
	background-color: var(--pink-500);
}
.navItem .teamIcon.green {
	background-color: var(--moss-500);
}

.teamChoicesIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-size: 28px 28px;
	font-size: 1em;
	font-variation-settings: var(--bold);
	padding: 5px;
	height: 29px;
	width: 29px;
	text-align: center;
	color: var(--white);
	background-color: var(--darkBlue);
	border-radius: 50%;
	flex-shrink: 0;
}
.teamChoicesIcon.purple {
	background-color: var(--purple-600);
}
.teamChoicesIcon.pink {
	background-color: var(--pink-500);
}
.teamChoicesIcon.green {
	background-color: var(--moss-500);
}
.teamChoicesIcon.purple:hover {
	background-color: var(--analytics-900);
}
.teamChoicesIcon.pink:hover {
	background-color: var(--analytics-900);
}
.teamChoicesIcon.green:hover {
	background-color: var(--team-900);
}
.teamChoicesIcon.selected {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100);
	box-shadow: var(--focus-ring-4px-primary-100);
}

.userIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 40px;
	width: 40px;
	text-align: center;
	cursor: pointer;
	fill: var(--gray);
}

.userIcon:hover {
	background-color: var(--lightGray);
	border-radius: 25px;
}

.userIconAlt {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	background-size: 28px 28px;
	font-size: 0.9375em;
	padding: 5px;
	height: 28px;
	width: 28px;
	text-align: center;
	color: var(--white);
	border: 1px solid var(--gray);
	background-color: var(--gray);
	border-radius: 50%;
}

.defaultTeamIcon {
	height: 40px;
	width: 40px;
	cursor: pointer;
	fill: var(--gray);
}

.defaultTeamIcon:hover {
	background-color: var(--lightGray);
	border-radius: 25px;
}

.navItem .teamIcon:hover {
	background-color: var(--darkerBlue);
	cursor: pointer;
}
.navItem .teamIcon.purple:hover {
	background-color: var(--analytics-900);
}
.navItem .teamIcon.pink:hover {
	background-color: var(--analytics-900);
}
.navItem .teamIcon.green:hover {
	background-color: var(--team-900);
}

.teamIcon p {
	margin: 20% 0%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.arrowIcon,
.fadedArrowIcon {
	margin-left: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.searchIcon {
	left: 280px;
	position: absolute;
	padding: 3px;
	color: var(--gray);
}

.closeIcon {
	fill: var(--gray);
	background-size: 20px 30px;
	height: 30px;
	width: 30px;
	padding: 5px;
	cursor: pointer;
	z-index: 2;
}

.unreadCircleIconTurq {
	position: absolute;
	height: 10px;
	width: 10px;
	margin-left: -4px;
	margin-top: -11px;
	fill: var(--turqMain);
	padding: 2px;
	border-radius: 25px;
	border: 1px solid var(--turqMain);
}

.unreadCircleIconRed {
	position: absolute;
	height: 10px;
	top: -4px;
	right: -4px;
	width: 10px;
	margin-left: -11px;
	/* margin-top: -3px; */
	fill: var(--warningRed);
	padding: 2px;
	border-radius: 25px;
}

.gotoDiscBlackIcon {
	height: 40px;
	width: 40px;
	-webkit-box-shadow: 0px 0px 3px var(--boxShadowDarkest);
	box-shadow: 0px 0px 3px var(--boxShadowDarkest);
	border-radius: 25px;
}

.orderPageIcon {
	min-height: 24px;
	min-width: 24px;
	height: 24px;
	width: 24px;
	margin-right: 6px;
}
.teamMemberPageIconSmall {
	background-color: var(--teamBtnBg);
	fill: var(--gray-65);
	border-radius: 50%;
	cursor: pointer;
	margin: 0px 6px;
	height: 20px;
	padding: 6px;
	width: 20px;
}
.teamMemberPageIconSmall:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.teamMemberPageIconLarge {
	cursor: pointer;
	height: 40px;
	width: 40px;
}
.orderPageIconLarge {
	width: 100%;
	height: 100%;
	max-height: 32px;
	max-width: 32px;
	margin-right: 13px;
}
.orderPageFileIcon {
	height: 24px;
	width: 24px;
	margin-right: 10px;
}

.orderPageDiscIcon {
	cursor: pointer;
	height: 22px;
	width: 22px;
	padding: 5px;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
	border-radius: 25px;
}
.orderPageDiscIcon:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}

.trackItemIcon {
	height: 28px;
	width: 28px;
}

.notificationIcon {
	height: 40px;
	width: 40px;
	fill: var(--white);
}

.notificationSubIcon {
	height: 16px;
	width: 16px;
	fill: var(--gray);
}

.analyticsIcon {
	margin-top: auto;
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
}

.clipboardIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: auto;
	background-color: var(--turqMain);
	border-radius: 50%;
	fill: var(--white);
	height: 36px;
	width: 36px;
	left: -2px;
}
.clipboardIcon svg {
	height: 20px;
	width: 20px;
}

.dualTurqBorderIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 48px;
	height: 48px;
	padding: 6px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 10px;
	border: 8px solid var(--primary-50);
	background: var(--primary-100);
	fill: var(--primary-600);
}

.infoTooltipIcon {
	fill: var(--gray-600);
	margin-left: 12px;
	height: 16px;
	width: 16px;
}

.breadcrumbIcon {
	fill: var(--gray-400);
	height: 16px;
	width: 16px;
}
.pipeDividerIcon {
	fill: var(--gray-300);
	height: 16px;
	width: 16px;
}

.fileMimeIcon svg {
	fill: var(--smoke-gray-300);
	height: 44px;
	width: 44px;
}
.fileMimeIcon h4 {
	position: absolute;
	font-variation-settings: 'wght' 700;
	background-color: var(--primary-600);
	color: var(--white);
	font-size: 0.625em;
	border-radius: 2px;
	padding: 2px 3px;
	height: 12px;
	width: 20px;
	top: 19px;
	left: 2px;
}
.fileMimeIcon.pdf h4 {
	background-color: var(--error-600);
}
